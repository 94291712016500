import React from 'react';
import isLoggedIn from 'src/utils/isLoggedIn';
import inBrowser from 'src/utils/inBrowser';
import Register from 'src/components/auth/Register';

const RegisterPage = () => {

    if (inBrowser && isLoggedIn) {
        window.location.href = '/account';
    }
    
    return (
        <div className="align-center">
            <Register />
        </div>
    );
};

export default RegisterPage;
